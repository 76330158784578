@font-face {
	font-family: 'Brandon Grotesque';
	font-style: normal;
	font-weight: 300;
	src: url('/assets/fonts/brandon/brandon_light.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Brandon Grotesque';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/fonts/brandon/brandon_reg.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Brandon Grotesque';
	font-style: normal;
	font-weight: 500;
	src: url('/assets/fonts/brandon/brandon_med.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Brandon Grotesque';
	font-style: normal;
	font-weight: 700;
	src: url('/assets/fonts/brandon/brandon_bld.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Brandon Grotesque';
	font-style: normal;
	font-weight: 900;
	src: url('/assets/fonts/brandon/brandon_blk.woff') format('woff');
	font-display: swap;
}
