.cdk-overlay-container {
	mat-tooltip-component {
		.mdc-tooltip .mdc-tooltip__surface {
			font-size: 12px;
			font-family: var(--abt-font-family);
			padding: 12px 16px;
			background-color: #2f2f39;
			line-height: 1.5;
		}
	}
}
