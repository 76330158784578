.abt-dialog {
	position: fixed;

	--width: 100%;
	--max-width: 600px;
	--height: auto;

	&.fullscreen {
		--max-width: 100%;
		--height: 100%;
	}

	&.crop-dialog {
		--height: 488px;
	}

	&.abt-dialog-lg {
		--max-width: 1088px;
		--height: 90vh;

		border-radius: 4px;
	}

	ion-backdrop {
		position: fixed;
		height: 100vh;
		cursor: initial;
	}
}

ion-popover {
	--width: 250px;
}

ion-modal.modal-default:not(.abt-menu):not(.overlay-hidden) {
	--backdrop-opacity: 0.5 !important;
}

.abt-menu {
	justify-content: flex-end;
	--max-width: 848px;
	--height: 100vh;

	ion-header {
		ion-toolbar {
			--background: linear-gradient(
				225deg,
				var(--color-red) 0%,
				var(--color-magenta) 100%
			);
			--min-height: 100px;
			--padding-start: 24px;
			--padding-end: 24px;
			color: var(--color-white);
			.toolbar-background {
				padding: 0px 24px;
				background: linear-gradient(
					225deg,
					var(--color-red) 0%,
					var(--color-magenta) 100%
				);
			}
			.toolbar-container {
				padding-top: 24px;
				padding-left: 24px;
				padding-right: 24px;
				min-height: 100px;
			}
		}
	}
	ion-content {
		--padding-start: 24px;
		--padding-end: 24px;
	}
	&-sm {
		--width: 690px;
	}
}

.tippy-popper {
	z-index: 30002 !important;
}
