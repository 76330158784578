mat-datepicker-toggle.mat-datepicker-toggle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 100%;

	button.mat-mdc-icon-button {
		--mdc-icon-button-icon-size: 20px;
		--mdc-icon-button-icon-color: var(--abbott-color-medium-gray);

		height: 100%;
		width: inherit;
		min-width: 0;

		padding: 0;
		border-radius: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		--button-hover-background-color: transparent;
		--button-hover-color: var(--abbott-color-primary);
	}

	.mat-mdc-button-persistent-ripple {
		display: none;
	}

	.mat-mdc-button-touch-target {
		height: 100%;
		width: 100%;
	}

	&.mat-datepicker-toggle-active {
		--mdc-icon-button-icon-color: var(--abbott-color-primary);
	}

	.mat-button-focus-overlay {
		display: none;
	}
}

mat-datepicker-content {
	font-family: var(--abt-header-font-family);

	button {
		--button-hover-background-color: transparent;
		--button-hover-color: var(--abbott-color-black);
	}

	button.mat-calendar-body-cell {
		min-width: 0;
		padding: 0;
	}

	.mat-calendar-controls button {
		min-width: 0;
	}
}
