@import 'lib/brandon';

:root {
	--abt-header-font-family: 'Brandon Grotesque';
	--abt-font-family: 'Georgia';
	--ion-font-family: var(--abt-font-family);
}

html,
body,
button {
	font-family: 'Georgia';
}
