button {
	--button-color: var(--abbott-color-primary-contrast);

	border-radius: var(--button-border-radius, 0);
	height: var(--button-height, 35px);
	font-size: var(--button-font-size, 16px);
	min-width: var(--button-min-width, 100px);
	border-color: var(--button-border-color, transparent);
	width: var(--button-width, initial);
	text-align: var(--button-text-align, center);
	border: var(--button-border, 0 solid transparent);

	display: var(--button-display, inline-flex);
	gap: 4px;
	align-items: center;
	justify-content: center;

	font-family: var(--button-font-family, var(--abt-header-font-family));
	font-weight: var(--button-font-weight, bold);
	text-transform: uppercase;

	white-space: nowrap;

	appearance: none;
	transition:
		background-position 0.2s,
		opacity 0.2s,
		color 0.2s,
		background-color 0.2s;

	background-size: 100% 200%;
	padding: var(--button-padding, 0 25px);
	cursor: pointer;

	color: var(--button-color, var(--abbott-color-primary-contrast));
	background-color: var(
		--button-background-color,
		var(--abbott-color-primary)
	);

	&[disabled] {
		opacity: 0.3;
		color: var(--button-color-disabled, var(--button-color));
		cursor: not-allowed;
		user-select: none;
	}

	& > * {
		--color: var(--button-color);
	}

	&:not([disabled]):hover {
		color: var(--button-hover-color, var(--abbott-color-primary-contrast));
		background-color: var(
			--button-hover-background-color,
			var(--abbott-color-primary-shade)
		);
		background-position: 0% -100%;
	}

	abt-spinner {
		--spinner-size: var(--button-spinner-size, 0);
		--spinner-color: var(--button-loading-color, var(--button-color));
	}

	// Enables the swiping animation when the button is hovered
	&.animate {
		background-image: linear-gradient(
			to bottom,
			var(--button-background-color, initial) 50%,
			var(
					--button-hover-background-color,
					var(--abbott-color-primary-shade)
				)
				50%
		);
	}

	&.prevent-focus {
		outline: 0;

		&:focus {
			abt-ui-icon {
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
	}

	&[color='monochromatic'] {
		--button-display: flex;
		--button-min-width: 35px;
		--button-border-color: var(--abbott-color-light-gray);
		--button-border: 1px solid var(--button-border-color);
		--button-background-color: var(--abbott-color-white);
		--button-color: var(--abbott-color-dark-gray);
		--button-hover-background-color: var(--abbott-color-app-light-gray);
	}

	&.ghost {
		--button-border-color: transparent !important;
		--button-background-color: transparent !important;
		--button-hover-background-color: transparent !important;

		--button-color: var(--abbott-color-primary);
		--button-hover-color: var(--abbott-color-primary-shade);
	}

	&.icon {
		--button-padding: 0;
		--button-width: 35px;
		--button-min-width: 0;
	}

	// Button: small
	// --------------------------------------------------
	&.small {
		--button-font-size: 14px;
		--button-height: 32px;
		--button-padding: 0 16px;
	}

	// Button: xsmall
	// --------------------------------------------------
	&.xsmall {
		--button-font-size: 12px;
		--button-height: auto;
		--button-padding: 4px;
	}

	&.prevent-outline {
		outline: none;
	}

	// Button: Block
	// --------------------------------------------------
	&.block {
		--button-width: 100%;
	}

	// Button: Text Align Left
	// --------------------------------------------------
	&.text-left {
		--button-text-align: left;
	}

	&[busy='true'],
	&[busy='true']:hover {
		--button-color: var(--abbott-color-primary);
		--button-background-color: var(--abbott-color-primary-contrast);
		--button-hover-color: var(--abbott-color-primary);
		--button-hover-background-color: var(--abbott-color-primary-contrast);
		--button-spinner-size: 10px;

		opacity: 0.6;
		pointer-events: none;
	}

	&[color='secondary'] {
		--button-color: var(--abbott-color-secondary-contrast);
		--button-background-color: var(--abbott-color-secondary);
		--button-loading-color: var(--abbott-color-secondary);

		--button-hover-color: var(--abbott-color-secondary-contrast);
		--button-hover-background-color: var(--abbott-color-secondary-shade);

		&[busy='true'] {
			--button-color: var(--abbott-color-secondary);
			--button-background-color: var(--abbott-color-secondary-contrast);
			--button-hover-color: var(--abbott-color-secondary);
			--button-hover-background-color: var(
				--abbott-color-secondary-contrast
			);
		}
	}

	&[color='success'] {
		--button-color: var(--abbott-color-success-contrast);
		--button-background-color: var(--abbott-color-success);
		--button-loading-color: var(--abbott-color-success);
		--button-hover-color: var(--abbott-color-success-contrast);
		--button-hover-background-color: var(--abbott-color-success-shade);

		&[busy='true'] {
			--button-color: var(--abbott-color-success);
			--button-background-color: var(--abbott-color-success-contrast);
			--button-hover-color: var(--abbott-color-success);
			--button-hover-background-color: var(
				--abbott-color-success-contrast
			);
		}
	}

	&[color='danger'] {
		--button-color: var(--abbott-color-danger-contrast);
		--button-background-color: var(--abbott-color-danger);
		--button-loading-color: var(--abbott-color-danger);
		--button-hover-color: var(--abbott-color-danger-contrast);
		--button-hover-background-color: var(--abbott-color-danger-shade);

		&[busy='true'] {
			--button-color: var(--abbott-color-danger);
			--button-background-color: var(--abbott-color-danger-contrast);
			--button-hover-color: var(--abbott-color-danger);
			--button-hover-background-color: var(
				--abbott-color-danger-contrast
			);
		}
	}

	&[color='medium-green'] {
		--button-color: var(--abbott-color-medium-green-contrast);
		--button-background-color: var(--abbott-color-medium-green);
		--button-loading-color: var(--abbott-color-medium-green);
		--button-hover-color: var(--abbott-color-medium-green-contrast);
		--button-hover-background-color: var(--abbott-color-medium-green-shade);

		&[busy='true'] {
			--button-color: var(--abbott-color-medium-green);
			--button-background-color: var(
				--abbott-color-medium-green-contrast
			);
			--button-hover-color: var(--abbott-color-medium-green);
			--button-hover-background-color: var(
				--abbott-color-medium-green-contrast
			);
		}
	}

	&[color='medium-gray'] {
		--button-color: var(--abbott-color-medium-gray);
		--button-background-color: var(--abbott-color-medium-gray);
		--button-loading-color: var(--abbott-color-medium-gray);
		--button-hover-color: var(--abbott-color-medium-gray);
		--button-hover-background-color: var(--abbott-color-medium-gray-shade);

		&[busy='true'] {
			--button-color: var(--abbott-color-medium-gray);
			--button-background-color: var(--abbott-color-medium-gray);
			--button-hover-color: var(--abbott-color-medium-gray);
			--button-hover-background-color: var(--abbott-color-medium-gray);
		}
	}

	&[color='white'] {
		--button-color: var(--abbott-color-secondary-contrast);
		--button-background-color: var(--abbott-color-secondary);
		--button-hover-color: var(--abbott-color-secondary-contrast);
		--button-hover-background-color: var(--abbott-color-secondary-shade);

		&.ghost {
			--button-color: var(--abbott-color-white);
			--button-hover-color: var(--abbott-color-white-shade);
		}
	}

	&[color='black'] {
		--button-color: var(--abbott-color-black);
	}
}
