// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import '@abbott/frontend/ui/font';
@import '@abbott/frontend/ui/style';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';


.completed-popover .popover-content {
	--width: 400px;
	border-radius: 0;

	@media (max-width: 740px) {
		left: 0 !important;
		width: 100%;
	}
}

.mat-pseudo-checkbox-checked {
	background: var(--color-primary) !important;
}

.mat-optgroup-label {
	font-family: var(--abt-header-font-family);
}

mat-option.mat-mdc-option .mdc-list-item__primary-text {
	color: var(--abbott-color-medium-gray) !important;
	font-size: 12px;
	font-family: var(--abt-header-font-family);
	font-weight: 700;
	text-transform: uppercase;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
	z-index: 99999 !important;
}

.multi-select {
	.mat-optgroup-label {
		cursor: pointer !important;
	}
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-abbott: (
	50: #009cde,
	100: #009cde,
	200: #009cde,
	300: #009cde,
	400: #009cde,
	500: #009cde,
	600: #009cde,
	700: #009cde,
	800: #009cde,
	900: #009cde,
	A100: #009cde,
	A200: #009cde,
	A400: #009cde,
	A700: #009cde,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(black, 0.87),
		400: rgba(black, 0.87),
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		A100: rgba(black, 0.87),
		A200: white,
		A400: white,
		A700: white,
	),
);

$abbott-app-primary: mat.m2-define-palette($mat-abbott);
$abbott-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$abbott-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$abbott-app-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $abbott-app-primary,
			accent: $abbott-app-accent,
			warn: $abbott-app-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($abbott-app-theme);

// Fixes date picker not displaying in modals
.cdk-overlay-container {
	z-index: 100000;
}
