.clickable {
	cursor: pointer;
}

.draggable {
	cursor: grab;
}

.cursor-disabled {
	cursor: not-allowed;
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.break-word {
	word-break: break-word;
}

.hidden,
[hidden] {
	display: none !important;
}
