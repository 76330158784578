a {
	--anchor-text-hover-decoration: underline;

	font-weight: var(--anchor-font-weight, 500);
	height: var(--anchor-height, auto);
	text-align: var(--anchor-text-align, left);
	font-size: var(--anchor-font-size, 14px);
	font-family: var(--anchor-font-family, var(--abt-font-family));

	color: var(--anchor-color, var(--abbott-color-primary));
	background-color: var(--anchor-background-color, transparent);

	text-transform: var(--anchor-text-transform, none);
	text-decoration: var(--anchor-text-decoration, none);

	display: var(--anchor-display, inline-flex);
	align-items: center;
	cursor: pointer;

	&[disabled] {
		opacity: 0.3;
		color: var(--anchor-color-disabled, var(--anchor-color));
		cursor: not-allowed;
		user-select: none;
	}

	&:not([disabled]):hover {
		--anchor-text-decoration: underline;

		color: var(--anchor-hover-color, var(--abbott-color-primary-shade));
		background-color: var(--anchor-hover-background-color, transparent);
	}

	& > abt-ui-icon[name='back'] {
		margin-right: 2px;
	}

	&[color='white'] {
		--anchor-color: var(--color-white);
		--anchor-hover-color: var(--color-white);
	}

	&[color='light-blue'] {
		--anchor-color: var(--color-light-blue);
		--anchor-hover-color: var(--color-light-blue);
	}

	&[color='medium-gray'] {
		--anchor-color: var(--color-medium-gray);
		--anchor-hover-color: var(--color-medium-gray);
	}

	&:not([disabled]):hover {
		text-decoration: var(--anchor-text-hover-decoration);
	}
}

a.footer-link {
	--anchor-font-weight: bold;
	--anchor-font-size: 14px;
	--anchor-text-transform: uppercase;
	--anchor-font-family: var(--abt-header-font-family);
}

a.breadcrumb {
	--anchor-font-size: 12px;
	--anchor-text-transform: uppercase;
	--anchor-font-family: var(--abt-header-font-family);
}

a.bold {
	--anchor-font-weight: bold;
}

a.small {
	--anchor-font-size: 12px;
}

a.prevent-outline {
	outline: none;
}

a.text-left {
	--anchor-text-align: left;
}

a.nav-link {
	--anchor-font-weight: bold;
	--anchor-font-size: 14px;
	--anchor-text-transform: uppercase;
	--anchor-text-hover-decoration: none;
	--anchor-font-family: var(--abt-header-font-family);

	white-space: nowrap;
	transition: all 0.2s;
	align-items: center;

	// router link based nav shows differently than href based
	&.router {
		--anchor-color: var(--color-black);
		--anchor-hover-color: var(--anchor-color);
		--anchor-hover-background-color: var(--abbott-color-light-gray);

		padding: 8px 24px;
		position: relative;

		&.active {
			--anchor-color: var(--abbott-color-primary);
			background: var(--abbott-color-light-gray);
		}

		abt-ui-badge {
			position: absolute;
			right: 5px;
			top: 0;
			transform: translate(0, 38%);
		}
	}

	&:not(.router) {
		--anchor-color: var(--color-white);
		--anchor-hover-color: var(--abbott-color-light-gray);
	}
}
