$desktop-gap: 8px;

@for $i from 0 through 12 {
    .padding-#{$i} {
        padding: $desktop-gap * $i;
    }

    .height-padding-#{$i} {
        padding-top: $desktop-gap * $i;
        padding-bottom: $desktop-gap * $i;
    }

    .width-padding-#{$i} {
        padding-left: $desktop-gap * $i;
        padding-right: $desktop-gap * $i;
    }

    .top-padding-#{$i} {
        padding-top: $desktop-gap * $i;
    }

    .bottom-padding-#{$i} {
        padding-bottom: $desktop-gap * $i;
    }

    .left-padding-#{$i} {
        padding-left: $desktop-gap * $i;
    }

    .right-padding-#{$i} {
        padding-right: $desktop-gap * $i;
    }

    .margin-#{$i} {
        margin: $desktop-gap * $i;
    }

    .height-margin-#{$i} {
        margin-top: $desktop-gap * $i;
        margin-bottom: $desktop-gap * $i;
    }

    .width-margin-#{$i} {
        margin-right: $desktop-gap * $i;
        margin-left: $desktop-gap * $i;
    }

    .top-margin-#{$i} {
        margin-top: $desktop-gap * $i;
    }

    .bottom-margin-#{$i} {
        margin-bottom: $desktop-gap * $i;
    }

    .left-margin-#{$i} {
        margin-left: $desktop-gap * $i;
    }

    .right-margin-#{$i} {
        margin-right: $desktop-gap * $i;
    }

    /**
     * HALVES
     */

    @if ($i != 0) {
        .padding-#{$i}h {
            padding: $desktop-gap * calc($i / 2);
        }

        .height-padding-#{$i}h {
            padding-top: $desktop-gap * calc($i / 2);
            padding-bottom: $desktop-gap * calc($i / 2);
        }

        .width-padding-#{$i}h {
            padding-left: $desktop-gap * calc($i / 2);
            padding-right: $desktop-gap * calc($i / 2);
        }

        .top-padding-#{$i}h {
            padding-top: $desktop-gap * calc($i / 2);
        }

        .bottom-padding-#{$i}h {
            padding-bottom: $desktop-gap * calc($i / 2);
        }

        .left-padding-#{$i}h {
            padding-left: $desktop-gap * calc($i / 2);
        }

        .right-padding-#{$i}h {
            padding-right: $desktop-gap * calc($i / 2);
        }

        .margin-#{$i}h {
            margin: $desktop-gap * calc($i / 2);
        }

        .height-margin-#{$i}h {
            margin-top: $desktop-gap * calc($i / 2);
            margin-bottom: $desktop-gap * calc($i / 2);
        }

        .width-margin-#{$i}h {
            margin-right: $desktop-gap * calc($i / 2);
            margin-left: $desktop-gap * calc($i / 2);
        }

        .top-margin-#{$i}h {
            margin-top: $desktop-gap * calc($i / 2);
        }

        .bottom-margin-#{$i}h {
            margin-bottom: $desktop-gap * calc($i / 2);
        }

        .left-margin-#{$i}h {
            margin-left: $desktop-gap * calc($i / 2);
        }

        .right-margin-#{$i}h {
            margin-right: $desktop-gap * calc($i / 2);
        }
    }
}
