mat-select-search {
	display: block;
	width: 100%;

	.mat-select-search-input {
		padding: 16px;
		padding-right: 36px;
		box-sizing: border-box;
		width: 100%;
	}

	.mat-select-search-inner {
		position: absolute;
		top: 0;
		width: 100%;
		border-bottom: 1px solid #cccccc;
		background: white;
		z-index: 100;
		display: flex;
		align-items: center;

		&.mat-select-search-inner-multiple {
			width: 100%;
		}
	}

	.mat-select-search-clear {
		position: absolute;
		right: 0;
		top: 50%;
		bottom: auto;
		left: auto;
		transform: translateY(-50%);
		width: 35px;
		height: 35px;
		background: transparent;
	}
}
