input + abt-ui-label[type='label'],
.input-container + abt-ui-label[type='label'] {
	margin-top: 16px;
}

.input-container {
	position: relative;
	display: inline-flex;
	align-items: center;
	width: 100%;

	abt-ui-form-control-icon {
		position: absolute;
		right: 16px;
		display: none;
	}

	abt-max-length-counter {
		position: absolute;
		top: 0;
		right: 0;
		transform: translateY(-100%);
	}

	abt-rich-text-editor + abt-ui-form-control-icon {
		bottom: 16px;
	}
}

form {
	abt-ui-label {
		max-width: var(--input-width);
	}

	abt-ui-heading,
	abt-ui-label {
		&[required] {
			display: flex;

			&::after {
				content: '*';
				margin-left: 4px;
			}
		}
	}
}

form.submitted {
	.input-container {
		abt-rich-text-editor.ng-invalid + abt-ui-form-control-icon,
		abt-rich-text-editor.error + abt-ui-form-control-icon,
		abt-drop-search.ng-invalid + abt-ui-form-control-icon,
		input.ng-invalid + abt-ui-form-control-icon,
		input.error + abt-ui-form-control-icon {
			display: block;
			z-index: 2;
		}
	}
}

.input-container.submitted {
	abt-rich-text-editor.ng-invalid + abt-ui-form-control-icon,
	abt-rich-text-editor.error + abt-ui-form-control-icon,
	input.ng-invalid + abt-ui-form-control-icon,
	input.error + abt-ui-form-control-icon {
		display: block;
	}
}

form {
	abt-ui-form-alert {
		opacity: 0;
		transition: opacity 0.2s ease-in;
	}
}

.submitted {
	&.ng-invalid {
		abt-ui-form-alert {
			opacity: 1;
		}
	}

	abt-ui-form-alert.visible {
		opacity: 1;
	}

	.input-container {
		[formControlName].ng-invalid,
		abt-rich-text-editor.error,
		input.error {
			--input-border-color: var(--color-red);
		}
	}

	input.ng-invalid {
		--input-border-color: var(--color-red);
		border: 1px solid var(--color-red);
	}

	abt-ui-password-input {
		input.ng-invalid {
			--input-border-color: var(--color-red);
			border: 1px solid var(--color-red);
		}
	}

	abt-geolocation-input.ng-invalid {
		--input-border-color: var(--color-red);
	}

	abt-select.ng-invalid select,
	abt-organization-autocomplete.ng-invalid abt-ui-drop-search,
	abt-date-picker.ng-invalid input {
		border: 1px solid var(--color-red);
	}
}
