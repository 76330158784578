input,
select,
textarea {
	height: var(--input-height);
	width: 100%;
	max-width: var(--input-width);
	border: 1px solid var(--input-border-color);

	border-radius: 2px;
	background-color: var(--color-white);
	padding: 0 12px;
	font-size: var(--input-font-size);
	font-family: var(--abt-header-font-family);
	box-sizing: border-box;

	transition: all 0.2s;

	&:active,
	&:focus,
	&.active {
		outline: 0;
		border: var(--input-active-border);
	}

	&::placeholder {
		color: var(--color-medium-gray);
		font-weight: 500;
	}

	&.submitted.ng-invalid {
		--input-border-color: var(--color-red);
	}

	&::-ms-clear {
		display: none;
		height: 0;
		width: 0;
	}

	&[disabled] {
		cursor: not-allowed !important;
	}
}

select[disabled],
textarea[disabled],
input[disabled] {
	background: var(--abbott-color-app-light-gray);
}

textarea {
	--input-font-size: 14px;
	--input-height: auto;
	resize: vertical;
	padding: 8px 12px;
}

quill-editor {
	height: 100%;
	display: flex;
	border-radius: 2px;
	width: 100%;
	max-width: var(--input-width);
	flex-direction: column-reverse;
	border: 1px solid var(--input-border-color);

	--button-min-width: 0;

	.ql-snow {
		&.ql-container {
			border: none !important;
			overflow: hidden;
		}

		&.ql-toolbar {
			border: none !important;
			transition: opacity 500ms ease-in-out;
		}
	}
}

quill-view-html {
	.ql-snow {
		.ql-editor {
			--font-family: var(--abt-font-family);
			font-size: 16px;
			padding: 0;
		}
	}

	&.line-clamp-3 {
		.ql-snow {
			.ql-editor {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}
		}
	}
}
